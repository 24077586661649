/* 文章列表卡片自定义样式 */
.card-custom-hover-style {
  transition-property: transform, box-shadow;
}

.card-custom-hover-style .arco-card-body {
  padding: 10px;
}

.card-custom-hover-style:hover {
  transform: translateY(-4px);
  box-shadow: 0 4px 10px rgb(var(--gray-3));
}

.card-custom-hover-style img {
  transition: scale 0.15s ease-in-out; /* 添加过渡效果 */
}

.card-custom-hover-style:hover img {
  /*opacity: 0.9; !* 设置透明度为 0.90 *!*/
  scale: 1.05;
}

.card-custom-selected-style {
  background-color: var(--color-fill-2);
  border: 1px solid var(--color-border-2);
}

.cover-image {
  height: 160px;
  overflow: hidden;
  border-bottom: 1px solid var(--color-border-1);
}

.article-card {
  margin-bottom: 10px;
}

.title-unread {
  font-weight: 500;
}

.title-read {
  color: var(--color-text-3);
  font-weight: 500;
}

.article-info {
  color: var(--color-text-3);
  font-size: 13px;
}

.feed-icon {
  margin-right: 8px;
  width: 16px;
  height: 16px;
  position: absolute;
  right: 2px;
  bottom: 12px;
}

.feed-icon-mini {
  margin-right: 8px;
  width: 16px;
  height: 16px;
  position: absolute;
  left: 10px;
  top: 14px;
}

.icon-starred {
  font-size: 13px;
  margin-left: 8px;
  color: var(--color-text-3);
}

.thumbnail {
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 4px;
}
