/*@media screen and (max-width: 700px) {*/
/*  .sidebar {*/
/*    display: none !important;*/
/*  }*/
/*}*/

/*@media screen and (max-width: 700px) {*/
/*  .main {*/
/*    padding-left: 0 !important;*/
/*    width: 100% !important;*/
/*  }*/
/*}*/

.arco-menu-collapse-button {
  display: none;
}

.arco-menu-collapse {
  width: 0 !important;
}

@media screen and (max-width: 992px) {
  .header {
    padding-left: 0 !important;
    width: 100% !important;
  }

  .trigger {
    display: block !important;
  }

  .arco-menu-collapse-button {
    display: flex !important;
    top: 12px;
  }

  .arco-layout-sider-light {
    box-shadow: none !important;
  }

  .main {
    width: 100% !important;
    padding-left: 0 !important;
  }
}

@media screen and (max-width: 700px) {
  .entry-col {
    width: 100% !important;
    border: none !important;
  }

  .entry-list {
    width: calc(100% - 20px) !important;
  }

  .article-content {
    display: block !important;
    z-index: 3 !important;
    position: absolute;
    box-sizing: border-box;
    top: 49px;
    left: 0;
    width: 100%;
    height: calc(100% - 49px);
    background-color: var(--color-bg-2);
  }

  .content-empty {
    display: none !important;
  }

  .action-buttons {
    display: none !important;
  }

  .action-buttons-mobile {
    display: block !important;
    z-index: 4;
  }

  .entry-panel {
    padding: 10px !important;
  }
}

::-webkit-scrollbar {
  background-color: transparent;
  width: 6px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb {
  background-color: var(--color-border-2);
  border-radius: 6px;
  border: 4px solid var(--color-border-2);
}

::-webkit-scrollbar-button {
  display: none;
}
