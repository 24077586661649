.arco-radio {
  padding-left: 0;
}

.custom-radio-card {
  padding: 8px 8px 1px 8px;
  border: 1px solid var(--color-border-2);
  border-radius: 8px;
  box-sizing: border-box;
  height: 80px;
  transition: all 0.1s linear;
}

input[name="card-radio-group"]:focus-visible + .custom-radio-card {
  box-shadow: 0 0 0 2px var(--color-primary-light-3);
}

.custom-radio-card:hover,
.custom-radio-card-checked,
.custom-radio-card:hover .custom-radio-card-mask,
.custom-radio-card-checked .custom-radio-card-mask {
  border-color: rgb(var(--primary-6));
}

.custom-radio-card-checked {
  background-color: var(--color-primary-light-1);
}

.theme-preview {
  height: 100%;
  object-fit: cover;
  border-radius: 4px 4px 0 0;
  border-left: 1px solid var(--color-border-2);
  border-top: 1px solid var(--color-border-2);
  border-right: 1px solid var(--color-border-2);
}

.setting-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
